.header {
  color: #fff;
  background-image: linear-gradient(284deg, #fedd4e, #fcb43a);
  align-items: center;
  min-height: 50px;
  margin-bottom: 1rem;
  padding: .5rem 1rem;
  display: flex;
}

body {
  font-family: Poppins, sans-serif !important;
}

.header a {
  color: #fff;
  text-decoration: none;
}

.header-title {
  font-size: 1.2rem;
  font-weight: normal;
}

.header-title:after {
  content: " ▸ ";
  padding: 0 .5rem;
}

.header-subtitle {
  font-size: 1.2rem;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

.search-panel {
  display: flex;
}

.search-panel__filters {
  flex: 1;
}

.search-panel__results {
  flex: 3;
}

.ais-Highlight-highlighted {
  color: inherit;
  font-size: inherit;
}

#pagination {
  text-align: center;
  margin: 2rem auto;
}

.col-header {
  text-align: right;
}

.col-header ul {
  list-style-type: none;
  display: inline-block;
}

.col-header ul li {
  margin-right: 5px;
  display: inline-block;
}

.col-md-3 a {
  padding: 5px;
  text-decoration: none !important;
}

.header-justify {
  justify-content: space-between;
}

.menu-icon {
  width: 28px;
  display: none;
}

@media screen and (width >= 768px) {
  .d-md-block {
    flex-basis: 50% !important;
    display: block !important;
  }

  .form-select {
    width: 100% !important;
  }
}

@media screen and (width <= 800px) {
  .col-header ul {
    background: #333;
    width: 100%;
    transition: max-height .6s;
    position: absolute;
    top: 70px;
    left: 0;
    overflow: hidden;
  }

  .col-header ul li {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 20px;
    display: block;
  }

  .col-header ul li a {
    color: #fff !important;
  }

  .menu-icon {
    cursor: pointer;
    margin-right: 10px;
    display: block;
  }
}
/*# sourceMappingURL=index.599a7a57.css.map */
