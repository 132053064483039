.header {
  display: flex;
  align-items: center;
  min-height: 50px;
  padding: 0.5rem 1rem;
  background-image: linear-gradient(284deg, #fedd4e, #fcb43a);
  color: #fff;
  margin-bottom: 1rem;
}

body{
  font-family: 'Poppins', sans-serif !important;
}

.header a {
  color: #fff;
  text-decoration: none;
}

.header-title {
  font-size: 1.2rem;
  font-weight: normal;
}

.header-title::after {
  content: ' ▸ ';
  padding: 0 0.5rem;
}

.header-subtitle {
  font-size: 1.2rem;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

.search-panel {
  display: flex;
}

.search-panel__filters {
  flex: 1;
}

.search-panel__results {
  flex: 3;
}

.ais-Highlight-highlighted {
  color: inherit;
  font-size: inherit;
}

/* #searchbox {
  margin-bottom: 2rem;
} */

#pagination {
  margin: 2rem auto;
  text-align: center;
}


/* The extra css styling */

.col-header {
  text-align: right;
}

.col-header ul{
  display: inline-block;
  list-style-type: none;
}

.col-header ul li {
  display: inline-block;
  margin-right: 5px;
}


.col-md-3 a {
  padding: 5px;
  text-decoration: none !important;

}

.header-justify {
  justify-content: space-between;
}


.menu-icon{
  width: 28px;
  display: none;
}



@media screen and (min-width: 768px){
  .d-md-block{
      display: block !important;
      flex-basis: 50% !important;
  }

  .form-select{
    width: 100% !important;
  }
  

  }

  @media screen and (max-width: 800px){
    .col-header ul{
      position: absolute;
      top: 70px;
      left: 0;
      background: #333;
      width: 100%;
      overflow: hidden;
      transition: max-height 0.6s;
    }

    .col-header ul li{
      display: block;
      margin-right: 20px;
      margin-top: 10px;
      margin-bottom: 10px; 
    }

    .col-header ul li a{
      color: #fff !important;

    }

    .menu-icon{
      display: block;
      cursor: pointer;
      margin-right: 10px;
    }

    
    }

